import { axios } from '@/utils/request'

const api = {
    getAll: 'charging/recharge/getAll', // 查询充值方案列表
    insert: 'charging/recharge/insert', // 添加充值方案
    update: 'charging/recharge/update', // 修改充值方案
    delete: 'charging/recharge/delete', // 删除充值方案
}
export default api

export const getAllApi = (params) => {
    return axios({
        url: api.getAll,
        method: 'post',
        params
    })
}

export const insertSchemeApi = (data) => {
    return axios({
        url: api.insert,
        method: 'post',
        data
    })
}

export const updateSchemeApi = (data) => {
    return axios({
        url: api.update,
        method: 'post',
        data
    })
}

export const deleteSchemeApi = (data) => {
    return axios({
        url: api.delete,
        method: 'post',
        data
    })
}
