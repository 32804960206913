<!-- 充值方案 --><!--收费方案列表 -->
<template>
  <div class="warp">
    <el-card class="warp-content">
      <!-- 搜素区域 -->
      <div class="page-select">
        <el-row style="width: 100%; margin: 0 0 15px 0">
          <el-col :span="24" style="text-align: right">
            <el-input
              placeholder="请输入创建人名称"
              class="search-input"
              prefix-icon="search"
              size="small"
              @input="searchInput"
              v-model="userInput"
              clearable
            ></el-input>
            <el-button type="primary" size="small" @click="searchInput">搜索</el-button>
            <el-button type="primary" size="small" @click="addClick" v-if="userLevel === 0">新增方案</el-button>
          </el-col>
        </el-row>
      </div>

      <!-- 表格区域 -->
      <div class="page-container">
        <el-table
          ref="tableRef"
          :data="tableData"
          style="width: 100%"
          max-height="630px"
          :header-cell-style="{ background: '#F2F4F8' }"
          @selection-change="handleSelectionChange"
        >
<!--          <el-table-column type="selection" align="center" width="80" />-->
          <el-table-column
            v-for="(item, index) in tableColumnData"
            :key="index"
            :prop="item.prop"
            :label="item.label"
            align="center"
          >
          </el-table-column>
            <el-table-column label="操作" align="center" v-if="userLevel === 0">
              <template #default="{ row }">
                <el-button type="text" @click="editClick(row)">编辑</el-button>
                <el-divider direction="vertical"></el-divider>
                <el-popconfirm
                    confirm-button-text="确定"
                    cancel-button-text="取消"
                    @confirm="confirmDelete(row)"
                    title="确认是否删除此方案？"
                >
                  <template #reference>
                    <el-button type="text">删除</el-button>
                  </template>
                </el-popconfirm>
              </template>
            </el-table-column>
        </el-table>
      </div>
      <!-- 分页区域 -->
      <div class="page-foot">
        <page-nation
          :total="total"
          @pageChange="handleSizeChange"
          @currentChange="handleCurrentChange"
        ></page-nation>
      </div>
      <!--编辑方案-->
      <el-dialog
        v-model="dialogVisible"
        width="40%"
        :title="dialogTitleName"
        :close-on-click-modal="false"
        @closed="resetEditForm"
      >
        <el-form
          label-width="95px"
          :inline="true"
          :model="editFormData"
          :rules="editDeviceRules"
          ref="editRuleFormRef"
        >
          <el-row :gutter="20">
            <el-col :span="22">
              <el-form-item label="充值金额：" prop="rechargeMoney">
                <el-input-number
                  placeholder="请输入充值金额"
                  v-model="editFormData.rechargeMoney"
                  controls-position="right"
                  :min="0"
                  style="width: 100%"
                ></el-input-number>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="20">
            <el-col :span="22">
              <el-form-item label="赠送金额：" prop="rechargePresenter">
                <el-input-number
                  placeholder="请输入赠送金"
                  v-model="editFormData.rechargePresenter"
                  controls-position="right"
                  :min="0"
                  style="width: 100%"
                ></el-input-number>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
        <template #footer>
          <span class="dialog-footer">
            <el-button @click="resetEditForm">取消 </el-button>
            <el-button
              type="primary"
              @click="submitEditForm('editRuleFormRef')"
            >保存</el-button>
          </span>
        </template>
      </el-dialog>
    </el-card>
  </div>
</template>

<script>
import { reactive, toRefs, onMounted, getCurrentInstance } from "vue-demi";
import pageNation from "@/components/base/pageNation.vue";
import { requestApi } from "@/utils/requestApi";
import { debounces } from "@/common/utils";
import {deleteSchemeApi, getAllApi, insertSchemeApi, updateSchemeApi} from "@/api/paySchemeManagement/payScheme";
export default {
  name: "AccountSearch",
  components: { pageNation },
  setup() {
    const { proxy } = getCurrentInstance();
    const data = reactive({
      searchValue: null, //搜索创建时间
      tableData: [], // 表格数据
      total: 0,
      pageSize: 10,
      currentPage: 1,
      userInfo: null,
      userLevel: 0, //用户等级
      selection: [],
      tableColumnData: [
        {
          prop: "createUser",
          label: "创建人名称",
        },
        {
          prop: "rechargeMoney",
          label: "充值金额",
        },
        {
          prop: "rechargePresenter",
          label: "赠送金额",
        },
        {
          prop: "createTime",
          label: "创建时间",
        },
      ],
      userInput: "",
      searchOrgName: "",
      searchDeviceAddress: "",
      searchDeviceStatus: "",
      searchDeviceAgency: "",
      searchChargnt: "",
      // 编辑用户对话框
      dialogVisible: false,
      dialogTitleName: "新增方案",
      // 编辑用户form
      editFormData: {
        rechargeMoney: 0,
        rechargePresenter: 0,
      },
      editDeviceRules: {
        rechargeMoney: [
          { required: true, message: '请输入充值金额', trigger: ['blur','change'] },
        ],
        rechargePresenter: [
          { required: true, message: '请输入赠送金', trigger: ['blur','change'] },
        ],
      },
    });
    onMounted(() => {
      proxy.userInfo = JSON.parse(localStorage.getItem("userInfo"));
      proxy.$nextTick(() =>{
        proxy.userLevel = proxy.userInfo.chargingMerchant.level
      })
      console.log(proxy.userInfo,'proxy.userInfo')
        methods.getAll();
    });
    // 节流搜索按钮
    const searchInput = debounces(() => {
      methods.getAll();
    }, 500);
    const methods = {
      // 查询充值方案列表
      async getAll () {
        await requestApi(
            () => {
              const params = {
                current: data.currentPage,
                size: data.pageSize,
                merchantId: data.userInfo.merchantId,
                createUser: data.userInput
              }
              return getAllApi(params)
            },
            (res) => {
              proxy.tableData = res.data.records
              if(proxy.tableData.length > 0) {
                for (let i = 0; i < proxy.tableData.length; i++) {
                  proxy.tableData[i].rechargeMoney = Number(proxy.tableData[i].rechargeMoney)
                  proxy.tableData[i].rechargePresenter = Number(proxy.tableData[i].rechargePresenter)
                }
              }
              proxy.total = res.data.total
              proxy.currentPage = res.data.current
              proxy.pageSize = res.data.size
            }
        )
      },
      // 表格分页 改变每页数量时触发
      handleSizeChange(val) {
        proxy.pageSize = val
        methods.getAll()
      },
      // 表格分页 改变页数时触发
      handleCurrentChange(val) {
        proxy.currentPage = val
        methods.getAll()
      },
      // 新增方案
      addClick() {
        proxy.dialogTitleName = "新增方案";
        data.dialogVisible = true;
      },
      // 点击编辑
      editClick(row) {
        // console.log(row);
        proxy.dialogTitleName = "编辑方案";
        data.dialogVisible = true;
        data.editFormData = JSON.parse(JSON.stringify(row))
      },
      // 保存
      submitEditForm(formName) {
        proxy.$refs[formName].validate(async (valid) => {
          if (valid) {
            if (proxy.dialogTitleName === "新增方案") {
              console.log(proxy.editFormData);
              await methods.addScheme(proxy.editFormData)
            } else {
              await methods.updateScheme(proxy.editFormData);
            }
            await methods.getAll();
            methods.resetEditForm()
          }
        });
      },
      async addScheme(params) {
        params.createUser = data.userInfo.accountNum
        params.merchantId = data.userInfo.merchantId
        await requestApi(
            () => {
              return insertSchemeApi(params)
            },
            (res) => {
              proxy.$notify({
                title: "成功",
                message: res.message,
                type: "success",
                duration: 1000,
              });
            }
        )
      },
      async updateScheme(params) {
        params.updateUser = data.userInfo.accountNum
        await requestApi(
            () => {
              return updateSchemeApi(params)
            },
            (res) => {
              proxy.$notify({
                title: "成功",
                message: res.message,
                type: "success",
                duration: 1000,
              });
            }
        )
      },
      resetEditForm() {
        data.dialogVisible = false;
        proxy.$refs.editRuleFormRef.resetFields()
      },
      confirmDelete(row) {
        const params = {
          rechargeId: row.rechargeId
        }
        requestApi(
            () => {
              return deleteSchemeApi(params)
            },
            (res) => {
              proxy.$notify({
                title: "成功",
                message: res.message,
                type: "success",
                duration: 1000,
              });
              methods.getAll();
            }
        )
      }
    };
    return {
      ...toRefs(data),
      searchInput,
      ...methods,
    };
  },
};
</script>
<style lang="less" scoped>
.warp {
  height: 100%;
  .warp-content {
    height: 100%;
  }
}
.page-select {
  width: 100%;
  margin: 0 auto;
}
.page-container {
  width: 100%;
  //margin-top: -28px;
  .imgeTou {
    width: 35px;
    height: 35px;
  }
}
.page-foot {
  width: 100%;
  margin-top: 14px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  .my-btn {
    height: 28px;
  }
}
.search-input {
  width: 202px;
  margin-right: 4px;
  margin-top: 6px;
}
.search-select{
  width: 100%;
}
.search-input-class {
  width: 100%;
}
/deep/.el-form-item__label {
  white-space: nowrap;
}
/deep/.el-form-item {
  width: 100%;
}
</style>
